<template>
    <div id="container">
        <el-main>
            <!-- 面包屑导航区域 -->
            <bread-crumb
                    :type="2"
                    :step="9"
            ></bread-crumb>

            <!-- 主干区域 -->
            <div class="content">
                <div class="h_title">
                    我的考试<span>关注“THUSSAT服务”服务号，第一时间接收考试提醒。</span>
                </div>
                <!-- 空组件 -->
                <empty title="暂无考试订单" v-if="orderList.length <= 0"></empty>
                <!-- 卡片区域 -->
                <el-card
                        class="box-card"
                        v-for="(item, index) in orderList"
                        :key="index"
                        :style="{ 'border-color': item.status == 2 ? '#563279' : '#b3b3b3' }"
                >
                    <div slot="header" class="clearfix">
                        <div class="fl">
                            <div class="h_info" v-if="item.status == 0 && item.exam_type != 7">
                                注：报名费用将于3-5个工作日沿原路返回至您的账户，请关注账户信息，如果有疑问，请联系考务：service@thussat.com。
                            </div>
                            <div class="h_info" v-if="item.status == 2 && (item.exam_type == 4 || item.exam_type == 7)">
                                注：考试名额已锁定，等待用户支付，剩余<span>{{remainTime[index]}}</span>自动关闭。请尽快完成支付。
                            </div>
                            <div class="h_info_2">
                                <span v-if="item.exam_type == 7">预定时间：</span>
                                <span v-else>报名时间：</span>
                                {{ item.add_time?item.add_time:'-' }}</div>
                            <div class="numner"
                                 v-if="item.exam_type == 4 || item.exam_type == 6 || item.exam_type == 7">
                                <span v-if="item.exam_type == 7">预定编号：</span>
                                <span v-else>报名编号：</span>
                                {{item.order_no?item.order_no:'-' }}
                            </div>
                        </div>

                        <div class="status">
                            <span v-if="item.status == 0">取消报名</span>
                            <span v-if="item.status == 1 && item.exam_type != 7">已报名</span>
                            <span v-if="item.status == 1 && item.exam_type == 7">已预定</span>
                            <span v-if="item.status == 2" style="color: #56327a">待付款</span>
                            <span v-if="item.status == 3 && item.exam_type != 7" style="color: #ee7602">报名中</span>
                            <span v-if="item.status == 3 && item.exam_type == 7" style="color: #ee7602">预定中</span>
                        </div>
                    </div>
                    <div class="text item">
                        <!-- 表格区域 -->
                        <el-table :data="[...item]" style="width: 100%" stripe>
                            <el-table-column
                                    align="center"
                                    label="姓名"
                            >{{item.student_name?item.student_name:'-'}}
                            </el-table-column>
                            <el-table-column
                                    align="left"
                                    label="证件号码"
                            >{{item.id_card?item.id_card:'-'}}
                            </el-table-column>
                            <el-table-column
                                    align="left"
                                    label="考试名称"
                            >{{item.exam_name?item.exam_name:'-'}}
                            </el-table-column>
                            <el-table-column
                                    align="left"
                                    label="考试科目"
                            >{{item.subject?item.subject:'-'}}
                            </el-table-column>
                            <el-table-column
                                    align="left"
                                    label="考试时间"
                            >{{item.execute_time?item.execute_time:'-'}}
                            </el-table-column>
                            <el-table-column
                                    v-if="item.exam_type == 4"
                                    align="left"
                                    label="预定考点"
                            >
                                {{ item.city ? item.city : "-" }}
                            </el-table-column>
                            <el-table-column
                                    v-if="item.exam_type == 7"
                                    align="left"
                                    label="预定考点省份"
                            >
                                {{ item.province ? item.province : "-" }}
                            </el-table-column>
                        </el-table>
                        <!-- 底部按钮 -->
                        <div class="foot-btn">
                            <div class="foot_price" v-if="item.exam_type != 7">
                                报名费用：<span>￥{{ item.amount }}</span>
                            </div>
                            <el-button
                                    v-if="item.exam_status == 3 && item.status == 2 && item.amount>0"
                                    class="btn_3"
                                    @click="confirmSignUp(item)"
                            >支付费用
                            </el-button>
                            <el-button
                                    v-if="item.exam_status == 3 && item.status == 2 && item.amount==0"
                                    class="btn_3"
                                    @click="confirmSignUp(item)"
                            >
                                <span v-if="item.exam_type == 7">进入考试</span>
                                <span v-else>立即报名</span>
                            </el-button>
                            <el-button
                                    v-if="item.exam_status == 3 && item.status == 0"
                                    class="btn_3"
                                    @click="toSignUp(item)"
                                    :disabled="isDisable"
                            >
                                <span v-if="item.exam_type == 7">进入考试</span>
                                <span v-else>重新报名</span>
                            </el-button>
                            <!--v-if="item.order_info && item.order_info.show_refund_status == 1"-->
                            <el-button
                                    v-if="item.exam_status == 3 && item.status == 1 && item.can_cancel == 1 && item.exam_type != 7"
                                    plain
                                    class="btn_2"
                                    @click="cancelSignUp(item)"
                            >取消报名
                            </el-button>
                            <el-button
                                    v-if="item.order_info && item.order_info.show_postpone_status == 1"
                                    class="btn_3"
                                    @click="postponeHandle(item)"
                            >申请延期
                            </el-button>
                            <el-button
                                    v-if="item.exam_status == 3
                                    && item.status == 2
                                    && (item.exam_type == 4 || item.exam_type == 7)"
                                    plain
                                    class="btn_2"
                                    @click="cancelReserve(item)"
                            >取消预定
                            </el-button>
                            <el-button
                                    v-if="item.exam_status == 3 && item.status == 3"
                                    class="btn_3"
                                    @click="toSignUp(item)"
                                    :disabled="isDisable"
                            >
                                <span v-if="item.exam_type == 7">进入考试</span>
                                <span v-else>继续报名</span>
                            </el-button>
                            <el-button
                                    v-if="(item.exam_status >= 3 && item.exam_status < 9) &&
                                    (item.exam_type == 7)&&
                                    (item.status == 1)"
                                    plain
                                    class="btn_1"
                                    @click="toViewInfo(item)"
                            >
                                <span>查看预定信息</span>
                            </el-button>
                            <el-button
                                    v-if="(item.exam_status >= 3 && item.exam_status < 9) &&
                                    (item.exam_type == 4 || item.exam_type == 6)"
                                    plain
                                    class="btn_1"
                                    @click="toViewInfo(item)"
                            >
                                <span>查看报名信息</span>
                            </el-button>
                            <span style="color: #CCCCCC; display: inline-block;line-height: 36px;padding-right: 20px;"
                                  v-if="(item.exam_status >= 4 && item.exam_status < 9) &&
                                    (item.exam_type == 7) &&
                                    (item.status != 1)"
                            >预定已截止</span>
                        </div>
                    </div>
                </el-card>
            </div>
        </el-main>
        <!-- 取消预定弹框 -->
        <cancel-reserve
                :examuuid="examuuid"
                :reserveDialogFu="reserveDialogFu"
                @closeReserveDialog="closeReserveDialog"
        ></cancel-reserve>
        <!-- 取消报名弹框 -->
        <cancel-sign-up
                :examuuid="examuuid"
                :price="price"
                :cancelSignUpDialogFu="cancelSignUpDialogFu"
                @closeCSUDialog="closeCSUDialog"
        ></cancel-sign-up>
        <!-- 确认报名弹框（支付） -->
        <confirm-sign-up
                :examuuid="examuuid"
                :confirmSignUpDialogFu="confirmSignUpDialogFu"
                @closeCFSUDialog="closeCFSUDialog"
        ></confirm-sign-up>
        <!--相关说明弹框-->
        <agree-box
                :agreeBoxDialogFu="agreeBoxDialogFu"
                @closeABDialog="closeABDialog"
        ></agree-box>
    </div>
</template>

<script>
    import {getOrderList, delayOrder} from "r/index/order.js";
    // import {signUpInit} from "r/index/signUp";
    import {signUpInitReq} from "common/request";
    import {djTime, formatTime} from "s/time.js";
    import breadCrumb from "c/breadcrumb";
    import CancelReserve from "c/index/signUp/CancelReserve";
    import CancelSignUp from "c/index/signUp/CancelSignUp.vue";
    import ConfirmSignUp from "c/index/signUp/ConfirmSignUp";
    import empty from "c/empty"; // 空组件
    import AgreeBox from "c/index/signUp/AgreeBox";

    export default {
        name: "ViewInfo",
        data() {
            return {
                isDisable: false, // 禁用 false-不禁用，true-禁用
                timeObj: {},
                remainTime: [], // 剩余时间对象
                timer: {}, // 计时器对象
                orderList: [], // 订单状态 status 0-已取消订单 1-已报名 2-待支付
                remainTime0: "",
                reserveDialogFu: false, // 控制取消预定弹框显示与隐藏
                examuuid: "", // 考试id
                price: "", // 绑定的订单价格
                cancelSignUpDialogFu: false, // 控制取消报名弹框显示与隐藏
                confirmSignUpDialogFu: false, // 控制确认报名弹框显示与隐藏
                agreeBoxDialogFu: false, // 控制相关说明弹框的显示与隐藏
            };
        },
        created() {
            this.getOrderList();
        },
        methods: {
            // 关闭定时器
            clearTime() {
                for (let k in this.timer) {
                    clearInterval(this.timer[k]);
                }
            },
            // 获取报名订单列表
            async getOrderList() {
                // 发送请求的操作
                let {data: res} = await getOrderList();
                console.log(res);
                // 根据返回的状态码做想关的判断
                if (res.code !== 200) return this.$message.error(res.message);
                this.orderList = res.orderList;
                this.orderList.forEach((item, index) => {
                    item["id_card"] = res.student.id_card;
                    item["student_name"] = res.student.student_name;
                    if (item.end_time <= 0) {
                        console.log(item.end_time, 'o')
                        return;
                    }

                    if (item.status == 2) {
                        this.timeObj[index] = item.end_time;
                        this.remainTime[index] = djTime(item.end_time);

                        this.timer[index] = setInterval(() => {
                            console.log(this.timeObj[index], index, this.orderList[index]['status'])
                            if (this.timeObj[index] <= 0) {
                                console.log('aaa', index, this.timeObj[index])
                                clearInterval(this.timer[index]);
                                return this.getOrderList()
                            }
                            this.timeObj[index] -= 1;
                            this.remainTime[index] = djTime(this.timeObj[index]);
                            this.$forceUpdate();
                        }, 1000);
                    }
                });
            },
            // 查看报名信息
            toViewInfo(item) {
                window.localStorage.setItem("exam_uuid", item.exam_uuid);
                window.localStorage.setItem("order_no", item.order_no);
                window.localStorage.setItem("enroll_end_time", item.enroll_end_time);
                // 关闭定时器
                this.clearTime();
                this.$router.push({name: "ViewInfo"});
            },
            // 重新报名
            async toSignUp(item) {
                if (this.isDisable) {
                    return false;
                }
                this.isDisable = true;
                window.localStorage.setItem("exam_uuid", item.exam_uuid);
                window.localStorage.setItem("enroll_end_time", item.enroll_end_time);
                let status = await signUpInitReq(item.exam_uuid);
                if (status) {
                    // 关闭定时器
                    this.clearTime();
                }
                this.isDisable = false;
            },
            // 子组件触发，关闭相关说明弹框
            closeABDialog(val, type) {
                this.agreeBoxDialogFu = val;
                if (type) {
                    this.confirmSignUpDialogFu = true;
                }
            },
            // 子组件触发，关闭确认报名弹框
            closeCFSUDialog(val, type) {
                this.confirmSignUpDialogFu = val;
                if (type) {
                    // 关闭定时器
                    this.clearTime();
                }
            },
            // 子组件触发，取消预定子组件回调
            closeReserveDialog(val, type) {
                this.reserveDialogFu = val;
                if (type) {
                    // 关闭定时器
                    this.clearTime();
                    this.$router.replace({name: "SignUpLists"});
                }
            },
            // 子组件触发，取消报名子组件回调
            closeCSUDialog(val, type) {
                this.cancelSignUpDialogFu = val;
                if (type) {
                    // 关闭定时器
                    this.clearTime();
                    this.$router.replace({name: "cancelspplysuccess"});
                }
            },
            // 确认报名
            confirmSignUp(item) {
                window.localStorage.setItem("exam_uuid", item.exam_uuid);
                window.localStorage.setItem("exam_type", item.exam_type);
                window.localStorage.setItem("enroll_end_time", item.enroll_end_time);
                this.examuuid = item.exam_uuid;
                this.agreeBoxDialogFu = true;
            },
            // 取消预定按钮
            cancelReserve(item) {
                window.localStorage.setItem("exam_uuid", item.exam_uuid);
                window.localStorage.setItem("enroll_end_time", item.enroll_end_time);
                this.examuuid = item.exam_uuid;
                this.reserveDialogFu = true;
            },
            // 取消报名按钮
            cancelSignUp(item) {
                window.localStorage.setItem("exam_uuid", item.exam_uuid);
                window.localStorage.setItem("enroll_end_time", item.enroll_end_time);
                this.price = item.amount;
                this.examuuid = item.exam_uuid;
                this.cancelSignUpDialogFu = true;
            },
            // 申请延期按钮
            postponeHandle(item) {
                if (item.order_info && item.order_info.postpone_status == 1) {
                    return this.$message.info('您已提交延期申请!');
                }
                this.$confirm('是否确认申请考试延期至8月TACA综合测试?', '申请延期', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: ''
                }).then(async () => {
                    let {data: res} = await delayOrder({
                        exam_uuid: item.exam_uuid,
                        delay: 1,
                    });
                    console.log(res);
                    if (res.code !== 200) return this.$message.error(res.message);
                    this.$message.success('考试延期申请成功！');
                    await this.getOrderList();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
        },
        destroyed() {
            this.clearTime()
        },
        components: {
            breadCrumb,
            CancelReserve,
            ConfirmSignUp,
            CancelSignUp,
            empty,
            AgreeBox
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #container {
        width: 100%;
        min-height: 80%;
        margin: 0 auto;
        overflow: auto;

        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 0 50px 50px;

            .content {
                background-color: #ffffff;
                padding: 40px 60px 58px 60px;
                // height: 1080px;

                .h_title {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    span {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-left: 12px;
                    }
                }

                ::v-deep .el-card {
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    box-shadow: 0 0 0;
                    margin-top: 24px;

                    .el-card__header {
                        border-bottom: 0;
                        padding: 0;
                        padding: 18px 30px 18px 40px;
                        height: auto;

                        .clearfix {
                            width: 100%;
                            display: flex;
                            flex-flow: row;

                            .fl {
                                flex: 5;

                                .h_info {
                                    font-size: 14px;
                                    font-family: PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 20px;
                                    margin-bottom: 5px;

                                    span {
                                        font-size: 30px;
                                        color: #ee7602;
                                        font-family: PingFangSC-Regular;
                                    }
                                }
                                .h_info_2{
                                    font-size: 14px;
                                    font-family: PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 20px;
                                    margin-bottom: 5px;
                                }

                                .numner {
                                    float: left;
                                    height: 26px;
                                    line-height: 26px;
                                    font-size: 14px;
                                    font-family: PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }

                            .status {
                                flex: 1;
                                font-size: 20px;
                                font-family: PingFangSC-Light;
                                font-weight: 300;
                                color: #999999;
                                text-align: right;
                            }
                        }
                    }

                    .el-card__body {
                        padding: 0;

                        .el-table {
                            &::before {
                                height: 0;
                            }
                        }

                        // 底部按钮
                        .foot-btn {
                            height: 54px;
                            padding: 9px 0;
                            border-top: 1px solid #e0e0e0;
                            text-align: right;

                            .foot_price {
                                float: left;
                                line-height: 36px;
                                font-size: 14px;
                                font-family: PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                margin-left: 20px;

                                span {
                                    font-size: 18px;
                                    font-family: PingFang SC;
                                    font-weight: 400;
                                    color: #563279;
                                }
                            }

                            .el-button {
                                float: right;
                                width: 120px;
                                height: 36px;
                                border: 1px solid #b3b3b3;
                                margin: 0 20px 0 0;
                                border-radius: 4px;
                                padding: 0;
                            }

                            .btn_1 {
                                color: #563279;
                                border-color: #563279;
                            }

                            .btn_2 {
                                color: #999999;
                                border-color: #b3b3b3;
                            }

                            .btn_3 {
                                color: #ffffff;
                                border-color: #563279;
                                background-color: #563279;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
