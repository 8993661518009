<template>
  <div class="empty_box">
    <el-image :src="require('../assets/images/empty.png')"></el-image>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "empty",
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
.empty_box {
  text-align: center;
  margin: 20px 0;
  .el-image {
    width: 200px;
    height: 200px;
  }
  .title {
    font-size: 16px;
    color: #563279;
  }
}
</style>
